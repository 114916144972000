import React from "react";
import classNames from "classnames";
import { Accordion } from "@molecules";
import { Container, Text } from "@atoms";

const AccordionList = ({
  bgColor,
  border,
  className: _className,
  copy,
  items,
}) => {
  return (
    <section
      className={classNames(
        "py-20 md:py-28",
        {
          "bg-gray": bgColor === "gray",
          "bg-green": bgColor === "green",
          "border-t-2 border-black": border,
        },
        _className
      )}
    >
      <Container variant="xs">
        <Text richText className="text-center">
          {copy}
        </Text>
        {!!items?.length && (
          <div
            className={classNames("flex flex-col", {
              "mt-12 md:mt-20": copy,
            })}
          >
            {items.map((item, i) => {
              return (
                <Accordion key={item.uid} order={i} color={bgColor} {...item} />
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

AccordionList.defaultProps = {};

export default AccordionList;
